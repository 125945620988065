import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";


import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

// layouts
import { AdminComponent } from "./layouts/admin/admin.component";
import { AuthComponent } from "./layouts/auth/auth.component";

// admin views
import { DashboardComponent } from "./views/admin/dashboard/dashboard.component";
import { MapsComponent } from "./views/admin/maps/maps.component";
import { SettingsComponent } from "./views/admin/settings/settings.component";
import { TablesComponent } from "./views/admin/tables/tables.component";

// auth views
import { LoginComponent } from "./views/auth/login/login.component";
import { RegisterComponent } from "./views/auth/register/register.component";

// no layouts views
import { IndexComponent } from "./views/index/index.component";
import { LandingComponent } from "./views/landing/landing.component";
import { Ahsap_kalipciComponent} from './views/Jobs/ahsap_kalipci/ahsap_kalipci.component';
import { DocumentsComponent} from './views/documents/documents.component';
// components for views and layouts

import { AdminNavbarComponent } from "./components/navbars/admin-navbar/admin-navbar.component";
import { AuthNavbarComponent } from "./components/navbars/auth-navbar/auth-navbar.component";
import { CardBarChartComponent } from "./components/cards/card-bar-chart/card-bar-chart.component";
import { CardLineChartComponent } from "./components/cards/card-line-chart/card-line-chart.component";
import { CardPageVisitsComponent } from "./components/cards/card-page-visits/card-page-visits.component";
import { CardProfileComponent } from "./components/cards/card-profile/card-profile.component";
import { CardSettingsComponent } from "./components/cards/card-settings/card-settings.component";
import { CardSocialTrafficComponent } from "./components/cards/card-social-traffic/card-social-traffic.component";
import { CardStatsComponent } from "./components/cards/card-stats/card-stats.component";
import { CardTableComponent } from "./components/cards/card-table/card-table.component";
import { FooterAdminComponent } from "./components/footers/footer-admin/footer-admin.component";
import { FooterComponent } from "./components/footers/footer/footer.component";
import { FooterSmallComponent } from "./components/footers/footer-small/footer-small.component";
import { HeaderStatsComponent } from "./components/headers/header-stats/header-stats.component";
import { IndexNavbarComponent } from "./components/navbars/index-navbar/index-navbar.component";
import { MapExampleComponent } from "./components/maps/map-example/map-example.component";
import { IndexDropdownComponent } from "./components/dropdowns/index-dropdown/index-dropdown.component";
import { TableDropdownComponent } from "./components/dropdowns/table-dropdown/table-dropdown.component";
import { Kurumsal_drop_downComponent } from "./components/dropdowns/kurumsal_drop_down/kurumsal_drop_down.component";
import { NotificationDropdownComponent } from "./components/dropdowns/notification-dropdown/notification-dropdown.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { UserDropdownComponent } from "./components/dropdowns/user-dropdown/user-dropdown.component";


///news


import { ClickOutsideModule } from 'ng-click-outside';
import { Hizmetler_drop_downComponent } from './components/dropdowns/hizmetler_drop_down/hizmetler_drop_down.component';
import { Bilgi_bankasi_drop_downComponent } from './components/dropdowns/bilgi_bankasi_drop_down/bilgi_bankasi_drop_down.component';
import {TypingAnimationDirective  } from 'angular-typing-animation';
import {AkreditasyonComponent} from './views/akreditasyon/akreditasyon.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {FiyatlistesiComponent} from './views/fiyatlistesi/fiyatlistesi.component';
import {HakkimizdaComponent} from './views/hakkimizda/hakkimizda.component';
import {OrganizasyonyapisiComponent} from './views/organizasyonyapisi/organizasyonyapisi.component';
import {VizyonvemisyonComponent} from './views/vizyonvemisyon/vizyonvemisyon.component';
import {TarafsizlikComponent} from './views/tarafsizlik/tarafsizlik.component';
import {KalitepolitikamizComponent} from './views/kalitepolitikamiz/kalitepolitikamiz.component';
import {LogosComponent} from './views/logos/logos.component';
import {BasvurubelgeleriComponent} from './views/basvurubelgeleri/basvurubelgeleri.component';
import {ExammapsComponent} from './views/maps/exammaps.component';
import {NewsComponent} from './views/News/haberler/news.component';
import {DuyurularComponent} from './views/News/duyurular/duyurular.component';
import {Alci_sivaComponent} from './views/Jobs/alci_siva/alci_siva.component';
import {BetonarmeDemirciComponent} from './views/Jobs/betonarme-demirci/betonarme-demirci.component';
import {DuvarciComponent} from './views/Jobs/duvarci/duvarci.component';
import {SivaciComponent} from './views/Jobs/sivaci/sivaci.component';
import {SeramikKaroKaplamacisiComponent} from './views/Jobs/seramik-karo-kaplamacisi/seramik-karo-kaplamacisi.component';
import {InsaatBoyacisiComponent} from './views/Jobs/insaat-boyacisi/insaat-boyacisi.component';
import {IskeleKurulumElemaniComponent} from './views/Jobs/iskele-kurulum-elemani/iskele-kurulum-elemani.component';
import {AluminyumKaynakcisiComponent} from './views/Jobs/aluminyum-kaynakcisi/aluminyum-kaynakcisi.component';
import {CelikKaynakcisiComponent} from './views/Jobs/celik-kaynakcisi/celik-kaynakcisi.component';
import {InsaatIscisiComponent} from './views/Jobs/insaat-iscisi/insaat-iscisi.component';
import {DirencKaynakAyarcisiComponent} from './views/Jobs/direnc-kaynak-ayarcisi/direnc-kaynak-ayarcisi.component';
import { AllJobsComponent } from './views/Jobs/all-jobs/all-jobs.component';
import { InsaatMeslekleriComponent } from './views/Jobs/jobs-category/insaat-meslekleri/insaat-meslekleri.component';
import { ElektrikElektronikMeslekleriComponent } from './views/Jobs/jobs-category/elektrik-elektronik-meslekleri/elektrik-elektronik-meslekleri.component';
import { MakineMeslekleriComponent } from './views/Jobs/jobs-category/makine-meslekleri/makine-meslekleri.component';
import { KimyaPetrolLastikPlastikMeslekleriComponent } from './views/Jobs/jobs-category/kimya-petrol-lastik-plastik-meslekleri/kimya-petrol-lastik-plastik-meslekleri.component';
import { UlastirmaLojistikHaberlesmeMeslekleriComponent } from './views/Jobs/jobs-category/ulastirma-lojistik-haberlesme-meslekleri/ulastirma-lojistik-haberlesme-meslekleri.component';
import { VincTasiyiciSapanciMeslekleriComponent } from './views/Jobs/jobs-category/vinc-tasiyici-sapanci-meslekleri/vinc-tasiyici-sapanci-meslekleri.component';
import { MetalMeslekleriComponent } from './views/Jobs/jobs-category/metal-meslekleri/metal-meslekleri.component';
import {MeslekiyeterliliksistemiComponent} from './views/meslekiyeterliliksistemi/meslekiyeterliliksistemi.component';
import {BelgelendirmesureciComponent} from './belgelendirmesureci/belgelendirmesureci.component';
import {SikayetoneriitirazformuComponent} from './sikayetoneriitirazformu/sikayetoneriitirazformu.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    CardBarChartComponent,
    CardLineChartComponent,
    IndexDropdownComponent,
    Kurumsal_drop_downComponent,
    TableDropdownComponent,
    NotificationDropdownComponent,
    UserDropdownComponent,
    SidebarComponent,
    FooterComponent,
    FooterSmallComponent,
    FooterAdminComponent,
    CardPageVisitsComponent,
    CardProfileComponent,
    CardSettingsComponent,
    CardSocialTrafficComponent,
    CardStatsComponent,
    CardTableComponent,
    HeaderStatsComponent,
    MapExampleComponent,
    AuthNavbarComponent,
    AdminNavbarComponent,
    IndexNavbarComponent,
    AdminComponent,
    AuthComponent,
    MapsComponent,
    SettingsComponent,
    TablesComponent,
    LoginComponent,
    RegisterComponent,
    IndexComponent,
    LandingComponent,
    Ahsap_kalipciComponent,
    Hizmetler_drop_downComponent,
    Bilgi_bankasi_drop_downComponent,
    TypingAnimationDirective,
    AkreditasyonComponent,
    DocumentsComponent,
    FiyatlistesiComponent,
    HakkimizdaComponent,
    OrganizasyonyapisiComponent,
    VizyonvemisyonComponent,
    TarafsizlikComponent,
    KalitepolitikamizComponent,
    LogosComponent,
    BasvurubelgeleriComponent,
    ExammapsComponent,
    NewsComponent,
    DuyurularComponent,
    Alci_sivaComponent,
    BetonarmeDemirciComponent,
    DuvarciComponent,
    SivaciComponent,
    SeramikKaroKaplamacisiComponent,
    InsaatBoyacisiComponent,
    IskeleKurulumElemaniComponent,
    AluminyumKaynakcisiComponent,
    CelikKaynakcisiComponent,
    InsaatIscisiComponent,
    DirencKaynakAyarcisiComponent,
    AllJobsComponent,
    InsaatMeslekleriComponent,
    ElektrikElektronikMeslekleriComponent,
    MakineMeslekleriComponent,
    KimyaPetrolLastikPlastikMeslekleriComponent,
    UlastirmaLojistikHaberlesmeMeslekleriComponent,
    VincTasiyiciSapanciMeslekleriComponent,
    MetalMeslekleriComponent,
    MeslekiyeterliliksistemiComponent,
    BelgelendirmesureciComponent,
    SikayetoneriitirazformuComponent
  ],
  imports: [BrowserModule, AppRoutingModule,ClickOutsideModule,PdfViewerModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}

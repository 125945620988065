import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-card-profile",
  templateUrl: "./card-profile.component.html",
})
export class CardProfileComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-card-social-traffic",
  templateUrl: "./card-social-traffic.component.html",
})
export class CardSocialTrafficComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
